/* ------------------------------ */
/* 100 - thin */
/* ------------------------------ */
@font-face {
	font-family: 'Morganite';
	src: url('Morganite-Thin.eot');
	src: url('Morganite-Thin.eot?#iefix') format('embedded-opentype'), url('Morganite-Thin.woff2') format('woff2'), url('Morganite-Thin.woff') format('woff'),
		url('Morganite-Thin.ttf') format('truetype'), url('Morganite-Thin.svg#Morganite-Thin') format('svg');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Morganite';
	src: url('Morganite-ThinItalic.eot');
	src: url('Morganite-ThinItalic.eot?#iefix') format('embedded-opentype'), url('Morganite-ThinItalic.woff2') format('woff2'), url('Morganite-ThinItalic.woff') format('woff'),
		url('Morganite-ThinItalic.ttf') format('truetype'), url('Morganite-ThinItalic.svg#Morganite-ThinItalic') format('svg');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}
/* ------------------------------ */
/* 200 - extra light */
/* ------------------------------ */
@font-face {
	font-family: 'Morganite';
	src: url('Morganite-ExtraLight.eot');
	src: url('Morganite-ExtraLight.eot?#iefix') format('embedded-opentype'), url('Morganite-ExtraLight.woff2') format('woff2'), url('Morganite-ExtraLight.woff') format('woff'),
		url('Morganite-ExtraLight.ttf') format('truetype'), url('Morganite-ExtraLight.svg#Morganite-ExtraLight') format('svg');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Morganite';
	src: url('Morganite-ExtraLightItalic.eot');
	src: url('Morganite-ExtraLightItalic.eot?#iefix') format('embedded-opentype'), url('Morganite-ExtraLightItalic.woff2') format('woff2'), url('Morganite-ExtraLightItalic.woff') format('woff'),
		url('Morganite-ExtraLightItalic.ttf') format('truetype'), url('Morganite-ExtraLightItalic.svg#Morganite-ExtraLightItalic') format('svg');
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}
/* ------------------------------ */
/* 300 - light */
/* ------------------------------ */
@font-face {
	font-family: 'Morganite';
	src: url('Morganite-Light.eot');
	src: url('Morganite-Light.eot?#iefix') format('embedded-opentype'), url('Morganite-Light.woff2') format('woff2'), url('Morganite-Light.woff') format('woff'),
		url('Morganite-Light.ttf') format('truetype'), url('Morganite-Light.svg#Morganite-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Morganite';
	src: url('Morganite-LightItalic.eot');
	src: url('Morganite-LightItalic.eot?#iefix') format('embedded-opentype'), url('Morganite-LightItalic.woff2') format('woff2'), url('Morganite-LightItalic.woff') format('woff'),
		url('Morganite-LightItalic.ttf') format('truetype'), url('Morganite-LightItalic.svg#Morganite-LightItalic') format('svg');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}
/* ------------------------------ */
/* 400 - book */
/* ------------------------------ */
@font-face {
	font-family: 'Morganite';
	src: url('Morganite-Book.eot');
	src: url('Morganite-Book.eot?#iefix') format('embedded-opentype'), url('Morganite-Book.woff2') format('woff2'), url('Morganite-Book.woff') format('woff'),
		url('Morganite-Book.ttf') format('truetype'), url('Morganite-Book.svg#Morganite-Book') format('svg');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Morganite';
	src: url('Morganite-BookItalic.eot');
	src: url('Morganite-BookItalic.eot?#iefix') format('embedded-opentype'), url('Morganite-BookItalic.woff2') format('woff2'), url('Morganite-BookItalic.woff') format('woff'),
		url('Morganite-BookItalic.ttf') format('truetype'), url('Morganite-BookItalic.svg#Morganite-BookItalic') format('svg');
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}
/* ------------------------------ */
/* 500 - medium  */
/* ------------------------------ */
@font-face {
	font-family: 'Morganite';
	src: url('Morganite-Medium.eot');
	src: url('Morganite-Medium.eot?#iefix') format('embedded-opentype'), url('Morganite-Medium.woff2') format('woff2'), url('Morganite-Medium.woff') format('woff'),
		url('Morganite-Medium.ttf') format('truetype'), url('Morganite-Medium.svg#Morganite-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Morganite';
	src: url('Morganite-MediumItalic.eot');
	src: url('Morganite-MediumItalic.eot?#iefix') format('embedded-opentype'), url('Morganite-MediumItalic.woff2') format('woff2'), url('Morganite-MediumItalic.woff') format('woff'),
		url('Morganite-MediumItalic.ttf') format('truetype'), url('Morganite-MediumItalic.svg#Morganite-MediumItalic') format('svg');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}
/* ------------------------------ */
/* 600 - semibold  */
/* ------------------------------ */
@font-face {
	font-family: 'Morganite';
	src: url('Morganite-SemiBold.eot');
	src: url('Morganite-SemiBold.eot?#iefix') format('embedded-opentype'), url('Morganite-SemiBold.woff2') format('woff2'), url('Morganite-SemiBold.woff') format('woff'),
		url('Morganite-SemiBold.ttf') format('truetype'), url('Morganite-SemiBold.svg#Morganite-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Morganite';
	src: url('Morganite-SemiBoldItalic.eot');
	src: url('Morganite-SemiBoldItalic.eot?#iefix') format('embedded-opentype'), url('Morganite-SemiBoldItalic.woff2') format('woff2'), url('Morganite-SemiBoldItalic.woff') format('woff'),
		url('Morganite-SemiBoldItalic.ttf') format('truetype'), url('Morganite-SemiBoldItalic.svg#Morganite-SemiBoldItalic') format('svg');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}
/* ------------------------------ */
/* 700 - bold  */
/* ------------------------------ */
@font-face {
	font-family: 'Morganite';
	src: url('Morganite-Bold.eot');
	src: url('Morganite-Bold.eot?#iefix') format('embedded-opentype'), url('Morganite-Bold.woff2') format('woff2'), url('Morganite-Bold.woff') format('woff'),
		url('Morganite-Bold.ttf') format('truetype'), url('Morganite-Bold.svg#Morganite-Bold') format('svg');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Morganite';
	src: url('Morganite-BoldItalic.eot');
	src: url('Morganite-BoldItalic.eot?#iefix') format('embedded-opentype'), url('Morganite-BoldItalic.woff2') format('woff2'), url('Morganite-BoldItalic.woff') format('woff'),
		url('Morganite-BoldItalic.ttf') format('truetype'), url('Morganite-BoldItalic.svg#Morganite-BoldItalic') format('svg');
	font-weight: 700;
	font-style: italic;
	font-display: swap;
}
/* ------------------------------ */
/* 800 - extra bold */
/* ------------------------------ */
@font-face {
	font-family: 'Morganite';
	src: url('Morganite-ExtraBold.eot');
	src: url('Morganite-ExtraBold.eot?#iefix') format('embedded-opentype'), url('Morganite-ExtraBold.woff2') format('woff2'), url('Morganite-ExtraBold.woff') format('woff'),
		url('Morganite-ExtraBold.ttf') format('truetype'), url('Morganite-ExtraBold.svg#Morganite-ExtraBold') format('svg');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Morganite';
	src: url('Morganite-ExtraBoldItalic.eot');
	src: url('Morganite-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'), url('Morganite-ExtraBoldItalic.woff2') format('woff2'), url('Morganite-ExtraBoldItalic.woff') format('woff'),
		url('Morganite-ExtraBoldItalic.ttf') format('truetype'), url('Morganite-ExtraBoldItalic.svg#Morganite-ExtraBoldItalic') format('svg');
	font-weight: 800;
	font-style: italic;
	font-display: swap;
}
/* ------------------------------ */
/* 900 - black */
/* ------------------------------ */
@font-face {
	font-family: 'Morganite';
	src: url('Morganite-Black.eot');
	src: url('Morganite-Black.eot?#iefix') format('embedded-opentype'), url('Morganite-Black.woff2') format('woff2'), url('Morganite-Black.woff') format('woff'),
		url('Morganite-Black.ttf') format('truetype'), url('Morganite-Black.svg#Morganite-Black') format('svg');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Morganite';
	src: url('Morganite-BlackItalic.eot');
	src: url('Morganite-BlackItalic.eot?#iefix') format('embedded-opentype'), url('Morganite-BlackItalic.woff2') format('woff2'), url('Morganite-BlackItalic.woff') format('woff'),
		url('Morganite-BlackItalic.ttf') format('truetype'), url('Morganite-BlackItalic.svg#Morganite-BlackItalic') format('svg');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}
